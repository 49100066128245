document.addEventListener("DOMContentLoaded", () => {
  const width = window.innerWidth;
  if (width > 1024) {
    const swiper = new Swiper('.swiper.activities__swiper', {
      // Optional parameters
      direction: 'vertical',
      loop: true,
      slidesPerView: 4,
    });
    const swiper_another = new Swiper('.swiper.parnters__swiper', {
        // Optional parameters
        direction: 'vertical',
        loop: true,
        slidesPerView: 3,
      });
 }
});
// const swiper = new Swiper('.swiper.activities__swiper', {
//   // Optional parameters
//   direction: 'vertical',
//   loop: true,
//   slidesPerView: 4,
// });
// const swiper_another = new Swiper('.swiper.parnters__swiper', {
//     // Optional parameters
//     direction: 'vertical',
//     loop: true,
//     slidesPerView: 3,
//   });
